.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bg-dark1);
}

.wrapper-fullScreen {
  position: fixed;
}
