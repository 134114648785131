.link {
  text-decoration: none;
  color: #fff;
  padding: 10px 20px;
}

.link-external {
  text-decoration: underline;
  padding: 10px 0;
}
