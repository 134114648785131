.nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  padding: 0 10px;
}

.list {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0;
  padding: 0;
}
.list > li:not(:last-child) {
  margin-right: 10px;
}

.nav > a {
  padding: 10px;
}
