.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  align-items: flex-start;
  width: 100%;
}

.grid-container-start {
  align-items: flex-start;
}
.grid-container-center {
  align-items: center;
}
.grid-container-end {
  align-items: flex-end;
}

.grid-item.grid-width-1,
.grid-item.grid-width-2,
.grid-item.grid-width-3,
.grid-item.grid-width-4,
.grid-item.grid-width-5,
.grid-item.grid-width-6,
.grid-item.grid-width-7,
.grid-item.grid-width-8,
.grid-item.grid-width-9,
.grid-item.grid-width-10,
.grid-item.grid-width-11,
.grid-item.grid-width-12 {
  grid-column: span 6;
}

.grid-item > label {
  width: 100%;
}

@media all and (min-width: 700px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 1em;
  }

  .grid-item.grid-width-1 {
    grid-column: span 1;
  }

  .grid-item.grid-width-2 {
    grid-column: span 2;
  }

  .grid-item.grid-width-3 {
    grid-column: span 3;
  }

  .grid-item.grid-width-4 {
    grid-column: span 4;
  }

  .grid-item.grid-width-5 {
    grid-column: span 5;
  }

  .grid-item.grid-width-6 {
    grid-column: span 6;
  }

  .grid-item.grid-width-7 {
    grid-column: span 7;
  }

  .grid-item.grid-width-8 {
    grid-column: span 8;
  }

  .grid-item.grid-width-9 {
    grid-column: span 9;
  }

  .grid-item.grid-width-10 {
    grid-column: span 10;
  }

  .grid-item.grid-width-11 {
    grid-column: span 11;
  }

  .grid-item.grid-width-12 {
    grid-column: span 12;
  }
}
