.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: color ease-in 250ms, background-color ease-in 250ms;
  cursor: pointer;
  font-size: 1rem;
}

.button > svg:not(:only-child) {
  margin-right: 1em;
}

.button-primary {
  background-color: var(--color-primary-dark1);
  color: var(--color-text-white);
}
.button-secondary {
  background-color: var(--color-secondary-dark1);
  color: var(--color-text-white);
}

.button-destructive {
  background-color: var(--color-destructive-dark1);
  color: var(--color-text-white);
}

.button.button-small {
  padding: 5px;
}

.button.button-medium {
  padding: 10px;
}

.button.button-large {
  padding: 20px;
}

.button.button-fullWidth {
  width: 100%;
}

.button-primary:hover,
.button-primary:focus,
.button-primary:active {
  background-color: var(--color-primary-dark2);
}

.button-secondary:hover,
.button-secondary:focus,
.button-secondary:active {
  background-color: var(--color-secondary-dark2);
}

.button-destructive:hover,
.button-destructive:focus,
.button-destructive:active {
  background-color: var(--color-destructive-dark2);
}

.button:disabled {
  cursor: not-allowed;
}

.button-primary:disabled {
  background-color: var(--color-primary-dark1-disabled);
  color: var(--color-text-dark1-disabled);
}
.button-secondary:disabled {
  background-color: var(--color-secondary-dark1-disabled);
  color: var(--color-text-dark1-disabled);
}

.button-destructive:disabled {
  background-color: var(--color-destructive-dark1-disabled);
  color: var(--color-text-dark1-disabled);
}
