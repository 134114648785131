.item {
  list-style-type: none;
  padding: 10px;
  cursor: pointer;
}

.item:hover,
.item:focus {
  background-color: rgba(255, 255, 255, 0.08);
}
