.buttonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: color ease-in 250ms, background-color ease-in 250ms;
  cursor: pointer;
  font-size: 1rem;
  background-color: transparent;
}

.buttonIcon > svg:not(:only-child) {
  margin-right: 1em;
}

.buttonIcon-primary {
  color: var(--color-primary-dark1);
}
.buttonIcon-secondary {
  color: var(--color-secondary-dark1);
}

.buttonIcon-destructive {
  color: var(--color-destructive-dark1);
}

.buttonIcon.buttonIcon-small {
  padding: 5px;
}

.buttonIcon.buttonIcon-medium {
  padding: 5px;
}

.buttonIcon.buttonIcon-large {
  padding: 20px;
}

.buttonIcon-primary:hover,
.buttonIcon-primary:focus,
.buttonIcon-primary:active {
  color: var(--color-primary-dark2);
}

.buttonIcon-secondary:hover,
.buttonIcon-secondary:focus,
.buttonIcon-secondary:active {
  color: var(--color-secondary-dark2);
}

.buttonIcon-destructive:hover,
.buttonIcon-destructive:focus,
.buttonIcon-destructive:active {
  color: var(--color-destructive-dark2);
}

.buttonIcon:disabled {
  cursor: not-allowed;
}

.buttonIcon-primary:disabled {
  color: var(--color-primary-dark1-disabled);
}
.buttonIcon-secondary:disabled {
  color: var(--color-secondary-dark1-disabled);
}

.buttonIcon-destructive:disabled {
  color: var(--color-destructive-dark1-disabled);
}
