@import-normalize;
:root {
  /* color */
  --color-primary-dark1: #3645da;
  --color-primary-dark1-disabled: #3645da20;
  --color-primary-dark2: #6961e2;
  --color-primary-dark3: #8e7fea;
  --color-primary-dark4: #ad9df1;
  --color-primary-dark5: #cbbdf8;
  --color-primary-dark6: #e6ddff;
  --color-secondary-dark1: #555;
  --color-secondary-dark1-disabled: #55555520;
  --color-secondary-dark2: #646464;
  --color-secondary-dark3: #737373;
  --color-secondary-dark4: #838383;
  --color-secondary-dark5: #939393;
  --color-secondary-dark6: #a3a3a3;
  --color-destructive-dark1: #b30300;
  --color-destructive-dark1-disabled: #b3030020;
  --color-destructive-dark2: #bc351e;
  --color-destructive-dark3: #c35137;
  --color-destructive-dark4: #c96951;
  --color-destructive-dark5: #cc806c;
  --color-destructive-dark6: #ce9687;
  --color-bg-dark1: #292a2b;
  --color-input-border-dark1: #1a1a1a;
  --color-input-bg-dark1: #242424;
  --color-input-bg-dark1-focus: #2f2f37;
  --color-text-white: #fff;
  --color-text-dark1: #afafaf;
  --color-text-dark1-focus: #cfcfcf;
  --color-text-dark1-disabled: #7f7f7f;
  --color-text-dark2-disabled: #5c5c5c;
  --color-success: #0f0;
  --color-error: #f00;
  --color-required: #f00;

  /* z-index */
  --z-index-header: 10000;
  --z-index-overlay: 20000;
}
* {
  box-sizing: border-box;
}
body {
  height: 100vh;
  width: 100vw;
  background-color: #292a2b;
  color: #afafaf;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
