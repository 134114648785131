.wrapper {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 4px;
  grid-template-areas:
    'header'
    'left-side'
    'content'
    'right-side'
    'footer';
  max-width: 1200px;
  grid-template-rows:
    minmax(auto, min-content) minmax(auto, min-content) 1fr
    minmax(auto, min-content) minmax(auto, min-content);
}

.main-head {
  grid-area: header;
  background-color: #181a1b;
  position: sticky;
  top: 0;
  z-index: var(--z-index-header);
}

.content {
  grid-area: content;
  position: relative;
}
.left-side {
  grid-area: left-side;
  background-color: #181a1b;
}
.right-side {
  grid-area: right-side;
  background-color: #181a1b;
}
.main-footer {
  grid-area: footer;
  background-color: #181a1b;
}
@media (min-width: 700px) {
  .wrapper {
    grid-template-columns:
      [left-side] minmax(auto, min-content)
      [content] 1fr
      [right-side] minmax(auto, min-content);
    grid-template-rows: minmax(auto, min-content) 1fr minmax(auto, min-content);
    grid-template-areas:
      'header header  header'
      'left-side    content right-side'
      'footer footer  footer';
  }
}
.logo {
  height: 30px;
  width: auto;
}
.logo g {
  fill: white;
}

:global(.uppy-Dashboard.uppy-Dashboard--modal) {
  z-index: var(--z-index-overlay);
}

.skipLink {
  position: absolute;
  top: -40px;
  left: 0;
  background: var(--color-bg-dark1);
  color: white;
  padding: 8px;
  z-index: 100;
}

.skipLink:focus {
  top: 0;
}
